<!-- eslint-disable max-lines -->
<template>
  <div class="d-flex flex-column">
    <p class="body-text mb-6">
      {{ $t("guest.obligation_requested_infos") }}
      <a @click="$emit('click:openmodal')">
        {{ $t("guest.national_registration_form") }}
      </a>
      {{ $t("guest.opitional_infos_sinalized") }}
    </p>
    <div class="d-flex justify-center mb-6" style="width: 100%">
      <guest-avatar
        :photo="guest.photoUrlMemory || guest.photoUrl"
        size="200"
        @click:upload="$emit('click:upload-picture-mobile')"
      />
    </div>

    <z-checkbox
      v-if="!guest.masterGuest && hasMasterGuestData(reservation.id)"
      :label="$t('guest.masterguest_data_utilize')"
      :value="utilizeMasterGuestData"
      hide-details
      @change="$emit('use:masterguest', $event)"
    />
    <z-checkbox
      v-if="reservation.childrenNumber > 0 && !guest.masterGuest"
      data-cy="is-child"
      :label="$t('guest.this_guest_is_child')"
      :value="guest.isChild"
      hide-details
      @change="$emit('change', { isChild: $event })"
    />

    <z-section-divider
      start-opened
      :title="$t('guest.personal_data')"
      :icon-down="''"
      :icon-up="''"
    >
      <template #content>
        <div @click="isBilled && showWarningEditIsNotPossible()">
          <z-input
            data-cy="name"
            :label="$t('guest.name')"
            :value="guest.name"
            :disabled="isBilled"
            :error-messages="getErrorMessages(v$.name)"
            @input="$emit('change', { name: $event })"
          />
        </div>

        <div @click="isBilled && showWarningEditIsNotPossible()">
          <z-input
            data-cy="last-name"
            :label="$t('guest.last_name')"
            :value="guest.lastName"
            :disabled="isBilled"
            :error-messages="getErrorMessages(v$.lastName)"
            @input="$emit('change', { lastName: $event })"
          />
        </div>

        <z-checkbox
          class="mt-1"
          data-cy="inform-social-name"
          :label="$t('guest.inform_social_name')"
          :value="guest.informSocialName"
          @change="$emit('change', { informSocialName: $event })"
        />
        <z-input
          v-if="guest.informSocialName"
          class="grid-span-2"
          data-cy="social-name"
          :label="$t('guest.social_name')"
          :value="guest.socialName"
          :error-messages="getErrorMessages(v$.socialName)"
          @change="$emit('change', { socialName: $event })"
        />
        <z-select
          v-if="!config.personalData?.gender?.default"
          data-cy="gender"
          :label="$t('guest.gender')"
          :items="genders"
          :value="guest.gender"
          :error-messages="getErrorMessages(v$.gender)"
          @change="$emit('change', { gender: $event })"
        />
        <p style="font-size: 13px" class="mb-2">{{ $t("guest.birthdate") }}</p>
        <div class="d-flex">
          <z-input
            data-cy="birthdate-day"
            type="text"
            :hide-details="true"
            :label="$t('guest.day')"
            mask="##"
            inputmode="numeric"
            :error-messages="getErrorMessages(v$.birthdate)"
            :value="guest.birthdateDay"
            @input="$emit('change', { birthdateDay: $event })"
            @blur="$emit('blur')"
          />
          <z-select
            data-cy="birthdate-month"
            class="mx-3"
            style="width: 100%"
            :label="$t('guest.month')"
            :items="months"
            :value="guest.birthdateMonth"
            :hide-details="true"
            :error-messages="getErrorMessages(v$.birthdate)"
            @change="$emit('change', { birthdateMonth: $event })"
          />
          <z-input
            data-cy="birthdate-year"
            type="text"
            :hide-details="true"
            :label="$t('guest.year')"
            mask="####"
            inputmode="numeric"
            :error-messages="getErrorMessages(v$.birthdate)"
            :value="guest.birthdateYear"
            @input="$emit('change', { birthdateYear: $event })"
            @blur="$emit('change:watch-age')"
          />
        </div>
        <div class="error--container">
          <p class="error--text">
            {{
              getErrorMessages(v$.birthdate).length > 0
                ? getErrorMessages(v$.birthdate)[0]
                : ""
            }}
          </p>
        </div>
        <z-autocomplete
          v-if="!config.personalData.residenceCountry.default"
          data-cy="residency-country"
          item-value="code"
          :item-text="currentLangItemText"
          :label="$t('guest.residency_country')"
          :items="countries"
          :value="guest.countryId"
          :error-messages="getErrorMessages(v$.countryId)"
          @change="$emit('change', { countryId: $event })"
        />
        <z-autocomplete
          v-if="!config.personalData.nationalityCountry.default"
          data-cy="nationality"
          item-value="code"
          :item-text="currentLangItemText"
          :label="$t('guest.nationality')"
          :items="countries"
          :value="guest.nationalityId"
          :error-messages="getErrorMessages(v$.nationalityId)"
          @change="$emit('change', { nationalityId: $event })"
        />

        <z-combobox
          v-if="!config.personalData?.job?.default"
          data-cy="profession"
          :label="
            guest.isChild
              ? $t('guest.profession_optional')
              : $t('guest.profession')
          "
          :disabled="disabled"
          :value="guest.jobName"
          :items="jobs"
          :item-text="currentLangItemText"
          item-value="code"
          :error-messages="getErrorMessages(v$.jobName)"
          @change="onChangeJob"
        >
        </z-combobox>
      </template>
    </z-section-divider>

    <z-section-divider
      :title="$t('guest.document')"
      start-opened
      :icon-down="''"
      :icon-up="''"
    >
      <template #content>
        <z-select
          data-cy="document-type"
          item-value="value"
          item-text="text"
          :disabled="disabled"
          :items="documentTypes"
          :value="guest.documents.documentTypeId"
          :label="$t('guest.document_type')"
          :error-messages="getErrorMessages(v$.documents.documentTypeId)"
          @change="
            $emit('change', {
              'documents.documentTypeId': $event,
              'documents.documentNumber': '',
            })
          "
        />

        <z-input
          v-if="documentMask"
          key="document-with-mask"
          data-cy="document-number"
          :disabled="disabled"
          :value="guest.documents.documentNumber"
          :mask="documentMask"
          :label="$t('guest.document_number')"
          :error-messages="getErrorMessages(v$.documents.documentNumber)"
          @input="$emit('change', { 'documents.documentNumber': $event })"
        />
        <z-input
          v-else
          key="document-without-mask"
          data-cy="document-number"
          :disabled="disabled"
          :value="guest.documents.documentNumber"
          :mask="documentMask"
          :label="$t('guest.document_number')"
          :error-messages="getErrorMessages(v$.documents.documentNumber)"
          @input="$emit('change', { 'documents.documentNumber': $event })"
        />

        <template
          v-if="config.documentData?.mainDocument?.rules?.documentUpload"
        >
          <div
            v-if="guest.documents.front"
            class="d-flex justify-space-around align-center mb-4"
          >
            <img
              v-if="guest.documents.front"
              :error-messages="getErrorMessages(v$.documents.front)"
              data-cy="document-front"
              class="image-guest py-5"
              :src="guest.documents.frontImageMemory || guest.documents.front"
            />
            <img
              v-if="guest.documents.back"
              :error-messages="getErrorMessages(v$.documents.back)"
              data-cy="document-back"
              class="image-guest py-5"
              :src="guest.documents.backImageMemory || guest.documents.back"
            />
          </div>

          <z-btn
            v-if="
              !disabled && (!guest.documents.front || !guest.documents.back)
            "
            primary
            class="mt-2"
            data-cy="button-scan-document"
            icon="$add_rounded"
            width="100%"
            :text="$t('guest.scan_document')"
            @click="$emit('click:scan-document'), setAdditionalDocument(false)"
          />
          <z-btn
            v-else-if="!disabled"
            data-cy="button-upload-document-again"
            width="100%"
            class="mt-2"
            :text="$t('guest.scan_document_again')"
            icon="$add_rounded"
            @click="$emit('click:scan-document'), setAdditionalDocument(false)"
          />
          <div class="error--container">
            <p class="error--text">
              {{
                getErrorMessages(v$.documents.front).length > 0
                  ? getErrorMessages(v$.documents.front)[0]
                  : ""
              }}
            </p>
          </div>
        </template>

        <template
          v-if="!config.documentData?.additionalDocument?.rules?.hidden"
        >
          <p class="mb-5 body-bold" style="color: rgba(0, 0, 0, 0.6)">
            {{ $t("guest.document_additional") }}
          </p>

          <template v-if="additionalDocumentTypes.length">
            <z-select
              data-cy="document-type"
              item-value="value"
              item-text="text"
              :disabled="disabled"
              :items="additionalDocumentTypes"
              :value="guest.additionalDocument.documentTypeId"
              :label="$t('guest.document_type')"
              :error-messages="
                getErrorMessages(v$.additionalDocument.documentTypeId)
              "
              @change="
                $emit('change', {
                  'additionalDocument.documentTypeId': $event,
                })
              "
            />
            <z-input
              v-if="additionalDocumentMask"
              key="add-document-with-mask"
              data-cy="document-number"
              :label="$t('guest.document_number')"
              :value="guest.additionalDocument.documentNumber"
              :disabled="disabled"
              :mask="additionalDocumentMask"
              :error-messages="
                getErrorMessages(v$.additionalDocument.documentNumber)
              "
              @input="
                $emit('change', { 'additionalDocument.documentNumber': $event })
              "
            />
            <z-input
              v-else
              key="add-document-without-mask"
              data-cy="document-number"
              :label="$t('guest.document_number')"
              :value="guest.additionalDocument.documentNumber"
              :disabled="disabled"
              :error-messages="
                getErrorMessages(v$.additionalDocument.documentNumber)
              "
              @input="
                $emit('change', { 'additionalDocument.documentNumber': $event })
              "
            />
          </template>

          <template
            v-if="
              config.documentData?.additionalDocument?.rules?.documentUpload
            "
          >
            <div
              v-if="guest.additionalDocument.front"
              class="d-flex justify-space-around align-center mb-4"
            >
              <img
                v-if="guest.additionalDocument.front"
                :error-messages="getErrorMessages(v$.documents.front)"
                data-cy="document-front"
                class="image-guest py-5"
                :src="
                  guest.additionalDocument.frontImageMemory ||
                  guest.additionalDocument.front
                "
              />
              <img
                v-if="guest.additionalDocument.back"
                :error-messages="getErrorMessages(v$.documents.back)"
                data-cy="document-back"
                class="image-guest py-5"
                :src="
                  guest.additionalDocument.backImageMemory ||
                  guest.additionalDocument.back
                "
              />
            </div>
            <z-btn
              v-if="
                !disabled &&
                (!guest.additionalDocument.front ||
                  !guest.additionalDocument.back)
              "
              primary
              class="mt-2"
              data-cy="button-scan-document"
              icon="$add_rounded"
              width="100%"
              :text="$t('guest.scan_document_add')"
              @click="onScanClick"
            />
            <z-btn
              v-else-if="!disabled"
              data-cy="button-upload-document-again"
              width="100%"
              class="mt-2"
              :text="$t('guest.scan_document_again')"
              icon="$add_rounded"
              @click="onScanClick"
            />
            <div class="error--container">
              <p class="error--text">
                {{
                  getErrorMessages(v$.additionalDocument.front).length > 0
                    ? getErrorMessages(v$.additionalDocument.front)[0]
                    : ""
                }}
              </p>
            </div>
          </template>
        </template>
      </template>
    </z-section-divider>
    <v-divider></v-divider>
    <z-btn
      data-cy="next-btn"
      class="my-2"
      primary
      width="100%"
      :disabled="v$.$invalid && v$.$dirty"
      :text="$t('guest.next')"
      @click="onNext"
    />
    <z-btn :text="$t('guest.back')" width="100%" @click="$emit('click:back')" />
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core"
import { computed, onMounted, toRefs } from "vue"
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

import i18n from "@/i18n"
import { hasMasterGuestData } from "@/storage"
import { genders, months } from "@/utils/consts"
import { useDocuments } from "@/views/Reservation/useDocuments"

import { usePersonalInfoValidations } from "../useValidations"

export default {
  props: {
    jobs: {
      type: Array,
      default: () => [],
    },
    countries: {
      type: Array,
      default: () => [],
    },
    guest: {
      type: Object,
      default: () => {},
    },
    reservation: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    utilizeMasterGuestData: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  // eslint-disable-next-line max-lines-per-function
  setup(props, { emit }) {
    const { currentLang } = useNamespacedState("layout", ["currentLang"])
    const { guest } = toRefs(props)

    const { getErrorMessages, personalDataRules } = usePersonalInfoValidations({
      guest,
      config: props.config,
    })

    const { sendNotification } = useNamespacedActions("notifications", [
      "sendNotification",
    ])

    const {
      documentTypes,
      documentMask,
      additionalDocumentTypes,
      additionalDocumentMask,
      setAdditionalDocument,
    } = useDocuments()

    const v$ = useVuelidate(personalDataRules, guest)

    const onNext = () => {
      v$.value.$touch()

      if (!v$.value.$invalid) {
        emit("click:next")
      }
    }

    const onScanClick = () => {
      emit("click:scan-document")
      setAdditionalDocument(true)
    }

    const onChangeJob = (value) => {
      if (typeof value === "string") {
        emit("change", {
          jobName: value,
          jobId: null,
        })
      } else if (typeof value === "object") {
        emit("change", {
          jobName: value?.text ?? "",
          jobId: value?.value ?? "",
        })
      }
    }

    const showWarningEditIsNotPossible = () => {
      sendNotification({
        message: i18n.tc("alerts.reservation_billed_warn"),
        duration: 4000,
        color: "var(--yellow-600)",
      })
    }

    const checkIfReservationIsBilled = () => {
      return (
        props.reservation?.billed === true && props.guest.masterGuest === true
      )
    }

    const isBilled = checkIfReservationIsBilled()

    onMounted(() => {
      v$.value.$reset()
    })

    const currentLangItemText = computed(() => {
      return currentLang.value === "en" ? "name" : `name_${currentLang.value}`
    })

    return {
      months,
      genders,
      v$,

      currentLangItemText,
      onChangeJob,
      getErrorMessages,
      hasMasterGuestData,
      onNext,
      onScanClick,
      showWarningEditIsNotPossible,

      documentTypes,
      documentMask,
      additionalDocumentMask,
      additionalDocumentTypes,
      setAdditionalDocument,
      isBilled,
    }
  },
}
</script>

<style lang="scss" scoped>
.v-input--selection-controls {
  margin-top: 0;
}
.error--text {
  font-size: 0.75rem;
}
.error--container {
  height: 30px !important;
  padding-left: 12px;
  padding-top: 6px;
  width: 100%;
}
.image-guest {
  aspect-ratio: 1;
  height: 45%;
  object-fit: fill;
  transform: rotate(-90deg);
  width: 50%;
}
</style>
